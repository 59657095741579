import Bullet from 'components/Bullet';
import DescriptionWrapper from 'primitives/Hero/DescriptionWrapper';
import ExplicitLyrics from 'components/ExplicitLyrics';
import format from 'date-fns/format';
import HeroBackground from 'components/Hero/HeroBackground';
import HeroContent from 'primitives/Hero/HeroContent';
import HeroDescription from '../primitives/HeroDescription';
import HeroMidSection from 'primitives/Hero/HeroMidSection';
import HeroOverlay from 'components/Hero/HeroOverlay/HeroOverlay';
import HeroPlayButton from 'components/Player/HeroPlayButton';
import HeroPlaySection from 'primitives/Hero/HeroPlaySection';
import HeroRoot from 'primitives/Hero/HeroRoot';
import HeroTitle from 'primitives/Hero/HeroTitle';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import ThumbnailWrapper from 'primitives/Hero/ThumbnailWrapper';
import Title from 'components/Hero/HeroTitle/HeroTitle';
import { getArtistUrl } from 'utils/url';
import { PureComponent } from 'react';
import { StationTypeValue } from 'constants/stationTypes';
import { TabletBackground, Thumbnail } from 'components/Hero/helpers';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

type Props = {
  albumId: string;
  albumTitle: string;
  artistId: number;
  artistName: string;
  customRadioEnabled: boolean;
  explicitLyrics: boolean;
  mediaServerUrl: string;
  releaseDate: number;
  resetHero: () => void;
  seedId: string;
  setHasHero: (hasHero: boolean) => void;
  setHeroPremiumBackground: (
    image: string,
    color: string,
    noLogo?: boolean,
  ) => void;
  stationType: StationTypeValue;
  subscriptionType: number;
  tracks: Array<any>;
  translate: IGetTranslateFunctionResponse;
};

export default class AlbumHero extends PureComponent<Props> {
  componentDidMount() {
    this.props.setHasHero(true);
  }

  componentWillUnmount() {
    this.props.resetHero();
  }

  render() {
    const {
      albumTitle,
      albumId,
      artistId,
      artistName,
      customRadioEnabled,
      explicitLyrics,
      mediaServerUrl,
      releaseDate,
      seedId,
      stationType,
      subscriptionType,
      tracks,
      translate,
    } = this.props;

    return (
      <HeroRoot data-test="hero-container">
        <HeroBackground
          tabletBackground={
            <TabletBackground
              customBlur={40}
              mediaServerUrl={mediaServerUrl}
              seedId={albumId}
              seedType="album"
            />
          }
        />
        <HeroOverlay>
          <HeroMidSection>
            <ThumbnailWrapper>
              <Thumbnail
                mediaServerUrl={mediaServerUrl}
                seedId={albumId}
                seedType="album"
              />
            </ThumbnailWrapper>
            <HeroContent>
              <HeroTitle data-test="artist-album-hero-title">
                <Title clamp text={albumTitle} />
              </HeroTitle>
              <DescriptionWrapper>
                <NavLink to={getArtistUrl(artistId, artistName)}>
                  {artistName}
                </NavLink>
                <HeroDescription>
                  {format(releaseDate, 'MMM YYYY')}
                  <Bullet />
                  {translate(['1 Song', '{n} Songs', 'n'], {
                    n: Number(tracks.length),
                  })}
                </HeroDescription>
                {explicitLyrics ? <ExplicitLyrics full /> : null}
              </DescriptionWrapper>
              <HeroPlaySection>
                {customRadioEnabled ? (
                  <PlayButton
                    artistId={artistId}
                    ButtonComponent={HeroPlayButton}
                    playedFrom={PLAYED_FROM.PROF_ALBUM_PLAY}
                    seedId={seedId}
                    stationId={seedId}
                    stationType={stationType}
                    subscriptionType={subscriptionType}
                  />
                ) : null}
              </HeroPlaySection>
            </HeroContent>
          </HeroMidSection>
        </HeroOverlay>
      </HeroRoot>
    );
  }
}

import AlbumHero from './AlbumHero';
import ArtistSongRows from 'views/Artist/ArtistSongRows';
import CopyrightInfo from 'components/CopyrightInfo/CopyrightInfo';
import countryCodes from 'constants/countryCodes';
import DropdownWrapper from './primitives/DropdownWrapper';
import Head from './Head';
import ListenInApp from 'components/ListenInApp';
import NavLink from 'components/NavLink';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import ShareButton from 'components/Social/Share';
import SimilarArtistAside from '../SimilarArtistAside';
import { get } from 'lodash-es';
import { getArtistUrl } from 'utils/url';
import { GrowlIcons } from 'components/Growls/constants';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { Menu } from 'components/Tooltip';
import { PlaybackTypeValue, STATION_TYPE } from 'constants/stationTypes';
import { PureComponent } from 'react';
import {
  SaveDeleteComponent,
  SaveDeleteView,
} from 'modules/Analytics/helpers/saveDelete';
import { ShareTypes } from 'components/Social/Share/constants';
import { StationSoftgate } from 'state/Config/types';
import type { addAlbumToPlaylist as addAlbumToPlaylistAction } from 'state/Playlist/actions';
import type { showNotifyGrowl as showNotifyGrowlAction } from 'state/UI/actions';
import type { Track } from 'state/Tracks/types';

export type Props = {
  addAlbumToPlaylist: typeof addAlbumToPlaylistAction;
  albumId: number;
  allAccessPreview: boolean;
  artistId: number;
  artistName: string;
  copyright: string;
  countryCode: string;
  isAnonymous: boolean;
  isLoggedOut: boolean;
  isInternationalPlaylistRadioEnabled: boolean;
  onDemandEnabled: boolean;
  openSignup: (context: string) => void;
  publisher: string;
  saveAlbum: (id: number) => Promise<void>;
  showNotifyGrowl: typeof showNotifyGrowlAction;
  showSaveAlbumHeaderOverflow: boolean;
  stationSoftgate: StationSoftgate;
  title: string;
  tracks: Array<Track>;
  translate: IGetTranslateFunctionResponse;
};

export default class ArtistAlbum extends PureComponent<Props> {
  onAddAlbumToPlaylist = () => {
    const { albumId, addAlbumToPlaylist } = this.props;

    addAlbumToPlaylist({
      albumId,
      component: SaveDeleteComponent.Overflow,
      view: SaveDeleteView.AlbumProfile,
    });
  };

  onAddAlbumToMyMusic = () => {
    const {
      albumId,
      allAccessPreview,
      isAnonymous,
      isLoggedOut,
      openSignup,
      saveAlbum,
      showNotifyGrowl,
      stationSoftgate,
      translate,
    } = this.props;
    const hasSoftgate = get(stationSoftgate, STATION_TYPE.ARTIST);

    if (allAccessPreview && isAnonymous) {
      openSignup('all_access_Preview');
    } else if (isLoggedOut && hasSoftgate) {
      openSignup('reg-gate');
    } else {
      saveAlbum(albumId);

      showNotifyGrowl({
        icon: GrowlIcons.HeartFilled,
        title: translate('Album saved to Your Library'),
      });
    }
  };

  render() {
    const {
      artistId,
      artistName,
      albumId,
      copyright,
      countryCode,
      isInternationalPlaylistRadioEnabled,
      onDemandEnabled,
      publisher,
      showSaveAlbumHeaderOverflow,
      tracks,
      translate,
      title,
    } = this.props;

    if (!artistId || !albumId) return null;

    let stationType: PlaybackTypeValue;
    let seedIdToPlay;

    // If OD is disabled, we play artist radio instead
    if (onDemandEnabled) {
      seedIdToPlay = albumId;
      stationType = STATION_TYPE.ALBUM as PlaybackTypeValue;
    } else {
      seedIdToPlay = artistId;
      stationType = STATION_TYPE.ARTIST as PlaybackTypeValue;
    }

    const similar = (
      <SimilarArtistAside playedFrom={PLAYED_FROM.PROF_SONG_SIMILAR} />
    );
    return (
      <>
        <Head />
        <AlbumHero seedId={seedIdToPlay} stationType={stationType} />
        <ListenInApp seedId={artistId} seedType="artist" />
        <PageBody
          backLink={getArtistUrl(artistId, artistName) as string}
          dataTest={ViewName.ArtistAlbum}
          hasHero
          more={similar}
          title={artistName}
        >
          <SectionHeader title="Tracks">
            <DropdownWrapper>
              <Menu>
                <Menu.List>
                  {isInternationalPlaylistRadioEnabled ||
                  countryCode === countryCodes.US ? (
                    <Menu.Item>
                      <NavLink
                        key="AddToPlaylist"
                        onClick={this.onAddAlbumToPlaylist}
                        title={translate('Add to Playlist')}
                      >
                        {translate('Add to Playlist')}
                      </NavLink>
                    </Menu.Item>
                  ) : null}
                  {showSaveAlbumHeaderOverflow ? (
                    <Menu.Item>
                      <NavLink
                        key="SaveToMyMusic"
                        onClick={this.onAddAlbumToMyMusic}
                        title={translate('Save Album')}
                      >
                        {translate('Save Album')}
                      </NavLink>
                    </Menu.Item>
                  ) : null}
                  <Menu.Item>
                    <ShareButton
                      key="shareButton"
                      seedId={albumId}
                      seedType={STATION_TYPE.ALBUM}
                      stationName={`${artistName} - ${title}`}
                      type={ShareTypes.Link}
                    />
                  </Menu.Item>
                </Menu.List>
              </Menu>
            </DropdownWrapper>
          </SectionHeader>
          <ArtistSongRows
            alignTrackRow="center"
            artistId={artistId}
            artistName={artistName}
            playedFrom={PLAYED_FROM.PROF_ALBUM_PLAY}
            seedId={seedIdToPlay}
            stationType={stationType}
            tracks={tracks}
          />
          <CopyrightInfo copyright={copyright} publisher={publisher} />
        </PageBody>
      </>
    );
  }
}
